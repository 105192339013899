<template>
  <div
    :id="nclistId"
    class="page-container"
  >
    <!--phần header-->
    <n-button-header
      :content-btn-add="nclistbtnAddTitle"
      :show-btn-multi-delete="false"
      @clickAdd="handler_open_btnFunc(1)"
    />
    <!--Phần bảng-->
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataRows || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Tên-->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
          </b-media>
        </span>

        <!-- Column: Loại nội dung -->
        <span v-else-if="props.column.field === 'linkShare'">
          <span class="text-nowrap text-right">{{ props.row.linkShare }}</span>
        </span>
        <!--   Column:Trạng thái -->
        <span v-else-if="props.column.field === 'statusName'">
          <n-form-status
            :status-id="props.row.statusId"
            :status-name="props.row.statusName"
          />
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <!--          <span-->
          <!--            @click="handler_open_btnFunc(2, props.row.id)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="EditIcon"-->
          <!--              size="18"-->
          <!--              class="text-body"-->
          <!--            />-->
          <!--          </span>-->
          <span
            class="ml-2"
            @click="handler_open_btnFunc(3, props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
          <!--          <span>-->
          <!--            <b-dropdown-->
          <!--              variant="link"-->
          <!--              boundary="viewport"-->
          <!--              toggle-class="text-decoration-none"-->
          <!--              no-caret-->
          <!--            >-->
          <!--              <template v-slot:button-content>-->
          <!--                <feather-icon-->
          <!--                  icon="MoreVerticalIcon"-->
          <!--                  size="18"-->
          <!--                  class="text-body"-->
          <!--                />-->
          <!--              </template>-->
          <!--              &lt;!&ndash; xem chi tiet  &ndash;&gt;-->
          <!--              <n-action-car-catolog-->
          <!--                :send-data="props.row.actions"-->
          <!--                :id-car-catalog="props.row.id"-->
          <!--              />-->
          <!--            </b-dropdown>-->
          <!--          </span>-->
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :total-items="totalRecord"
      :current-page="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      id-modal-delete-no-param="idModalQuestion"
      :type="modalType"
      :content-tile="modalContent"
      @accept="handle_run_question"
    />

    <s-m-event
      :s-m-event-id="smEventId"
      :s-m-event-title="smEventTitle"
      :s-m-event-group="ncGroupId"
      @handler_func_button="handler_func_button"
    />
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { formatDateToDDMM } from '@core/utils/filter'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import NActionCarCatolog from '@/views/ndev-component/action/NActionCarCatolog.vue'
import NFormStatus from '@/views/ndev-component/NFormStatus.vue'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import SMEvent from '@/views/slide-manager/pages/Event/SMEvent.vue'
import SlideConstantsApi from '@/views/slide-manager/constants/SlideConstantsApi'

export default {
  components: {
    SMEvent,
    NButtonHeader,
    NFormStatus,
    ModalDeleteNoParam,
    NActionCarCatolog,
    VueGoodTable,
    BButton,
    MyPagination,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    nclistId: {
      type: String,
      default: 'nclist_id',
    },
    smEventId: {
      type: String,
      default: 'smEventId',
    },
    smEventTitle: {
      type: String,
      default: 'smEventId',
    },
    nclistbtnAddTitle: {
      type: String,
      default: 'Thêm mới',
    },
    nclistType: {
      type: Number,
      default: 0,
    },
    ncGroupId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          label: 'Tiêu đề',
          field: 'name',
        },
        {
          label: 'Đường dẫn liên kết',
          field: 'linkShare',
        },
        {
          label: 'Trạng thái',
          field: 'statusName',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          thClass: 'custom-action-table-class',
          tdClass: 'text-center',
        },
      ],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        groupId: this.ncGroupId,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '', // thông báo xóa
      showBtnMultiDelete: false,
      deleteIds: [], // ID xóa
      isActive: false,
      dataRows: [], // dữ liệu bảng
      totalRecord: 10, // tổng số trang
      modalType: '',
    }
  },
  computed: {},
  mounted() {
    this.fetchDataList()
  },
  methods: {
    // =========================hàm mới tạo ====================================//
    async fetchDataList() {
      const iResult = await NCoreHelper.excuteGET(this, SlideConstantsApi.SLIDE_HOME_GET_PAGING, this.urlQuery)
      this.dataRows = iResult.pageLists
      this.totalRecord = iResult.totalRecord
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        await this.fetchDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        await this.fetchDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataList()
    },
    selectRowTable(val) {
      this.deleteIds = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteIds.length > 0
    },
    // =========================================================================//
    getExecuteAction(type, data) {
      return NCoreHelper.GetExecuteAction(type, data)
    },
    handler_open_btnFunc(type, data) {
      switch (type) {
        case 1: {
          // Thêm mới
          switch (this.nclistType) {
            case 1: {
              // Slide trang chủ
              this.smEventTitle = 'Thêm mới slide trang chủ'
              break
            }
            case 2: {
              // Slide thiết kế xe
              break
            }
            default: {
              // Slide thiết kế xe
              break
            }
          }

          this.$bvModal.show(this.smEventId)
          break
        }
        case 2: {
          // Cập nhật
          switch (this.nclistType) {
            case 1: {
              // Giới thiệu
              this.$router.push({
                name: 'nc-gioi-thieu-update',
                params: { id: data },
              })
              break
            }
            case 2: {
              // Tin tức
              this.$router.push({
                name: 'nc-tin-tuc-update',
                params: { id: data },
              })
              break
            }
            default: {
              break
            }
          }
          break
        }
        case 3: {
          // Xóa
          this.deletedIdKey = []
          this.deletedIdKey.push(data)
          this.modalContent = this.$t('Bạn có muốn xóa không?')
          this.$bvModal.show('idModalQuestion')
          break
        }
        default: {
          // Thêm mới
          break
        }
      }
    },
    async handle_run_question() {
      const iResult = await NCoreHelper.excuteDELETE(this, SlideConstantsApi.SLIDE_HOME_DELETE, { model: JSON.stringify(this.deletedIdKey) }, 'Xóa dữ liệu thành công')
      if (iResult) {
        await this.fetchDataList()
        this.$bvModal.hide('idModalQuestion')
      }
    },
    async handler_func_button(type, val) {
      switch (type) {
        case 2: {
          break
        }
        case 3: {
          // Lưu
          await this.fetchDataList()
          break
        }
        default: {
          break
        }
      }
      this.$bvModal.hide(this.smEventId)
    },
  },
}
</script>

<style lang="scss">

</style>
