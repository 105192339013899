<template>
  <b-modal
    :id="SMEventId"
    size="md"
    :title="SMEventTitle"
    centered
    no-close-on-backdrop
    :hide-footer="true"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <label>Ảnh đại diện <span class="required">(*)</span></label>
            <b-row>
              <b-col
                xs="12"
                sm="5"
                md="4"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  @click="handler_open_library(1)"
                >
                  <feather-icon
                    icon="CloudIcon"
                    class="mr-50"
                  />
                  Chọn ảnh
                </b-button>
                <library-modal
                  :id-library-model="'id-library-modal'"
                  @handleCloseLibraryModal="handleCloseLibraryModal"
                />
              </b-col>
              <b-col
                xs="12"
                sm="7"
                md="8"
                xl="8"
                class="text-center"
              >
                <img
                  class="img-responsive"
                  style="width: 100%!important;"
                  :src="(avatarLink)"
                  alt=""
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          xl="12"
        >
          <b-form-group>
            <label>Tiêu đề</label>
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              placeholder="Nhập tiêu đề ..."
              autocomplete="off"
              autofocus
            />
          </b-form-group>
          <b-form-group>
            <label>Vị trí hiển thị</label>
            <cleave
              id="txtPrice"
              v-model="queryBody.position"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
              autocomplete="off"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group>
            <label>Link liên kết bài viết</label>
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <b-form-input
                  id="txtLinkShare"
                  v-model="queryBody.linkShare"
                  placeholder="link liên kết bài viết ..."
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="nmt-10px text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-1"
            @click="handler_func_button(3)"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            Quay về
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            class="ml-1"
            :disabled="loading"
            @click="handler_func_button(2)"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></div>
            <span v-if="loading"> Đang xử lý dữ liệu ...</span>
            <span v-else>Lưu dữ liệu</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'
import { maxLength, required } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import LibraryModal from '@/views/ndev-component/library/LibraryModal.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import SlideConstantsApi from '@/views/slide-manager/constants/SlideConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BForm,
    BFormTextarea,
    Cleave,
    BFormCheckbox,
    LibraryModal,
    SlideConstantsApi,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    SMEventId: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    SMEventTitle: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    SMEventType: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    SMEventGroup: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      queryBody: {
        name: '',
        position: 0,
        description: '',
        fileId: '',
        linkShare: '',
      },
      avatarLink: '',
      brochureName: '',
      libraryType: 0,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      status: 'not_accepted',
      loading: false,
    }
  },
  watch: {
    dataCarCatalogDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            price: 0,
            isContact: '',
          }
          this.avatarLink = ''
        }
      },
    },
  },
  methods: {
    ...mapActions('libraryStore', ['libraryStore_action_ResetBreadCom']),
    handleCreateCarCategory(bvModalEvt) {
      this.isStart = true
      bvModalEvt.preventDefault()
      this.$emit('handleCreateCarCategory', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        name: '',
        position: 0,
        description: '',
        fileId: '',
        linkShare: '',
      }
      this.avatarLink = ''
      this.loading = false
    },
    openInput() {
      this.handler_open_library()
      // document.getElementById('vue-file-upload-input')
      //     .click()
    },
    callThumbnail(fileExtension, filePath, fileType) {
      return NCoreHelper.GetThumbnail(false, fileExtension, filePath, '64x64', this.$serverfile, NCoreHelper.GetIsImageLocal(fileType))
    },
    handler_open_library(type) {
      this.libraryType = type
      this.libraryStore_action_ResetBreadCom()
      this.$bvModal.show('id-library-modal')
    },
    handleCloseLibraryModal(val) {
      // eslint-disable-next-line no-empty
      if (val === null || val === undefined) {

      } else {
        switch (this.libraryType) {
          case 1: {
            this.avatarLink = this.callThumbnail(val.fileExtension, val.filePath, val.fileType)
            this.queryBody.fileId = val.id
            break
          }
          case 2: {
            break
          }
          default: {
            break
          }
        }
      }
      this.$bvModal.hide('id-library-modal')
    },
    removeFile(index) {
      //= => Gọi api xóa đi hình ảnh này ======
      this.queryBody.avatar = null
      this.avatarLink = ''
    },
    async handler_func_button(type) {
      this.loading = true
      switch (type) {
        case 3: {
          this.$bvModal.hide(this.SMEventId)
          break
        }
        case 2: {
          // Lưu
          this.queryBody.groupId = this.SMEventGroup
          this.queryBody.position = parseFloat(this.queryBody.position.replace(/,/g, ''))
          const iResult = await NCoreHelper.excutePOST(this, SlideConstantsApi.SLIDE_HOME_CREATE, this.queryBody, 'Cập nhật slide trang chủ thành công')
          if (iResult) {
            this.$emit('handler_func_button', type)
            this.$bvModal.hide(this.smEventId)
          }
          break
        }
        default: {
          break
        }
      }
    },
  },
}
</script>
