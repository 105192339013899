<template>
  <div>
    <s-m-list
      :nclist-id="'slidelist-main'"
      :nclistbtn-add-title="'Thêm mới slide'"
      :nclist-type="1"
      :nc-group-id="1"
    />
  </div>
</template>

<script>

import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import SMList from '@/views/slide-manager/pages/SMList.vue'

export default {
  components: {
    SMList,
    BButton,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
